import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-458e6378"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "total"
};
import "vue";
export default {
  __name: 'zxHeader',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_NBar = _resolveComponent("NBar");
      const _component_tab = _resolveComponent("tab");
      const _component_router_view = _resolveComponent("router-view");
      const _component_foot = _resolveComponent("foot");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_NBar), _createVNode(_component_tab, {
        style: {
          "position": "fixed",
          "top": "40%",
          "z-index": "999",
          "right": "4px"
        }
      }), _createVNode(_component_router_view), _createVNode(_component_foot)]);
    };
  }
};