import { createApp} from 'vue';
import App from './App.vue';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@/style/big.scss";
import "@/style/small.scss"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from "./router/index.js"
import NBar from "./components/navigationBar"
import footer from "./components/footer"
import tab from "./components/tab"
const app = createApp(App)
app.use(ElementPlus)
app.use(router)
app.component('tab',tab)
app.component('NBar',NBar)
app.component('foot',footer)
app.mount('#app')
