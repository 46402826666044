import { createMemoryHistory, createRouter } from 'vue-router'
import zxHeader from '@/layouts/zxHeader.vue'
const routes = [
    {
        path: '/',
        name: 'layout',
        component: zxHeader,
        redirect: () => {
            return { path: '/layout/home' }
        }
    },
    {
        path: '/layout',
        component: zxHeader,
        children: [
            {
                path: 'home',
                component: () => import('@/views/zxhome.vue')
            },
            {
                path: 'services',
                component: () => import('@/views/services.vue')
            },
            {
                path: 'success',
                component: () => import('@/views/success.vue')
            },
            {
                path: 'news',
                component: () => import('@/views/news.vue')
            },
            {
                path: 'about',
                component: () => import('@/views/about.vue'),
                children: [
                    {   name:'honor',
                        path: 'honor',
                        component: () => import('@/views/subAbout/honor'),
                    },
                    {   name:'culture',
                        path: 'culture',
                        component: () => import('@/views/subAbout/culture'),
                    },
                    {   name:'employee',
                        path: 'employee',
                        component: () => import('@/views/subAbout/employee'),
                    }
                ]
            },
            {
                name:'message',
                path:'message',
                component:() => import('@/views/subpkg/message')
            }
        ]
    }


]
const router = createRouter({
    history: createMemoryHistory(),
    routes,
})
export default router